<template>
  <div class="plan_info"  v-if="recommendedPlans.length > 0">
    <div class="prod_block mb-3">
      <h2>Recommended Products</h2>
    </div>
    <div class="recommendedCart-wrap">
      <carousel :items="5" :margin="15" :dots="false" :nav="false" :responsive="{320:{items:2},576:{items:3},764:{items:3},990:{items:3},1140:{items:4}, 1920:{items:5}}">
        <template slot="prev"><span class="pendingArrow prevBtn"><i class="fas fa-arrow-left"></i></span></template>
        <template slot="next"  v-if="recommendedPlans.length > 4"><span class="pendingArrow nextBtn"><i class="fas fa-arrow-right"></i></span></template>
        <div id="blackoutError" style="color: red;" v-if="blackoutErr">{{blackoutErr}}</div>
        <div
            v-bind:key="index"
            v-for="(plan, index) in recommendedPlans"
        >
          <a  href="#" v-on:click="nextPage(plan.plan_id, plan.plan_name)">
            <div class="recommendPlans-grid h-100 card-block">
              <img :src="plan.plan_logo" alt="Critical" class="img-fluid" />
              <h2>{{ plan.plan_name }}</h2>
              <span class="starting-price"
              >Starting From:
                            <span class="brand-color">${{ plan.plan_price }}</span></span
              >
              <span class="check-btn">More Info</span>
              <!--                <div class="cart_button">
                                <button  type="button" name="button" id="addCart" v-on:click="addCart(plan.plan_id,plan.plan_pricing_id)">Add to Cart</button>
                              </div>-->
              <span class="recommend-categ">{{ plan.plan_category }}</span>
            </div>
          </a>
        </div>
      </carousel>
    </div>
    <b-modal id="alreadyActiveMsg" centered size="md"  v-model="alreadyActive">
      <template #modal-header>
        <div class="popUp-header">
          <h4>{{alreadyActiveMessage}}</h4>

          <b-button variant="close-btn" @click="alreadyActive=false">
            <i class="fas fa-times"></i>
          </b-button>
        </div>
      </template>
      <b-container fluid>
        <div class="planPop-details">
          <div class="content">
            <div class="button_box mb-3">
              <button class="submit" type="button" @click="alreadyActive=false;alreadyActiveMessage=''" name="button">OK</button>
            </div>
          </div>
        </div>
      </b-container>
    </b-modal>
  </div>
</template>
<script>
import carousel from 'vue-owl-carousel'
import axios from "axios";
import store, {MUTATION_HAS_RECOMMENDED_PLANS} from "../store";
export default {
  name:'CartRecommend',
  components: { carousel },
  data () {
    return {
      agent_id: "",
      group_id: "",
      eprocess: "",
      portal_id: "",
      plan_id: "",
      user_id: "",
      recommendedPlans: [],
      showHideRecommendedProduct: false,
      baseUrl: process.env.VUE_APP_RECOMMENDED_API,
      blackoutErr:null,
      alreadyActive:false,
      alreadyActiveMessage:null,
      tempId:''
    }
  },
  mounted() {
    let app = this;
    app.page = app.$route.name;
    app.eprocess = window.localStorage.getItem("eprocess");
    app.portal_id = window.localStorage.getItem("portal_id");
    app.tempId=window.localStorage.getItem('tempId');
    if (app.portal_id !== "") {
      app.getRecommendedPlans();
    }
  },
  methods: {
    nextPage: function (pid, planName) {
      this.$router.push("plan/" + pid + "/" + planName);
    },
    decryptIds: async function () {
      let app = this;
      let ids = {};
      ids["portalId"] = window.localStorage.getItem("portal_id");
      if (window.localStorage.getItem("planId")) {
        ids["planId"] = window.localStorage.getItem("planId");
      }
      if (window.localStorage.getItem("user_id")) {
        ids["userID"] = window.localStorage.getItem("user_id");
      }
      ids["agentId"] = window.localStorage.getItem("agent_id");
      ids["groupId"] = window.localStorage.getItem("group_id");
      let decryptUrl = `${app.baseUrl}/decrypt-detail`;
      await axios
          .post(decryptUrl, ids)
          .then((res) => {
            if (res.data[0].agentId) {
              app.agent_id = res.data[0].agentId;
            }
            if (res.data[0].groupId) {
              app.group_id = res.data[0].groupId;
            }
            if (res.data[0].portalId) {
              app.portal_id = res.data[0].portalId;
            }
            if (res.data[0].planId) {
              app.plan_id = res.data[0].planId;
            }
            if (res.data[0].userID) {
              app.user_id = res.data[0].userID;
            }
          })
          .catch((err) => {
            console.log(err);
          });
    },
    getRecommendedPlans: async function () {
      let app = this;
      await app.decryptIds();
      let url = `${app.baseUrl}/recommendation/popularity`;
      let params = {};
      let planIds;
      params["platformID"] = app.portal_id;
      params["userID"] = app.user_id ? app.user_id : null;
      params["enroll_type"] = window.localStorage.getItem("enroll_type");
      params["agentID"] = app.agent_id;
      if (app.plan_id) params["planID"] = app.plan_id;
      params["groupID"] = app.group_id;
      params["state"] = window.localStorage.getItem("state");
      url = url.replace("popularity", "association");
      await axios
          .get(url, {
            params: { ...params },
          })
          .then((res) => {
            if (app.page == "cart") {
              planIds = res.data.recs;
              if (planIds && planIds.length > 0) {
                store.commit(MUTATION_HAS_RECOMMENDED_PLANS, true);
              } else {
                store.commit(MUTATION_HAS_RECOMMENDED_PLANS, false);
              }
            } else {
              planIds = res.data[0].recs;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      let allPlans = [];
      if (Array.isArray(planIds)) {
        allPlans = planIds;
      } else {
        for (let [key, value] of Object.entries(planIds)) {
          console.log(`${key}: ${value}`);
          if (value && value.length > 0) {
            allPlans.push(value[0]);
          }
        }
      }
      if (allPlans && allPlans.length > 0) {
        app.getPlansDetails(allPlans);
      }
    },
    getPlansDetails: function (ids) {
      let url = `${
          this.baseUrl
      }/recommendation/plan-details?plan_list=${ids.toString()}`;
      axios
          .get(url)
          .then((res) => {
            this.recommendedPlans = res.data.data;
            this.recommendedPlans.forEach((item, index) => {
              let str = item.plan_category;
              this.recommendedPlans[
                  index
                  ].plan_category = `${str[0].toUpperCase()}${str.slice(1)}`;
            });
          })
          .catch((err) => {
            console.log(err);
          });
    },
    // Will be implemented later for whish list...
    /*   addCart: function (pid, ppid) {
         let app = this;
         axios.post(process.env.VUE_APP_API_BASE + '/add-plan-to-cart', {
           enrollment_id:this.tempId,
           plan_id: pid,
           plan_pricing_id: ppid,
         })
             .then((response)=> {
               console.log(response);
               if (response.data.status == 'success') {
                 this.$emit('planAdded')
               }
             })
             .catch((error)=> {
               console.log(error.response);
               if (error.response.data[0].status == 'error') {
                 // error.response.data.data.forEach(function(item) {
                 if (error.response.data[0].errorCode == 'blackout') {
                   app.blackoutErr = error.response.data[0].message;
                 } else if (error.response.data[0].errorCode == 'already-active') {
                   app.alreadyActive = true;
                   app.alreadyActiveMessage = error.response.data[0].message;
                 } else {
                   app.blackoutErr = null;
                   app.alreadyActive.value = false;
                 }
               }
             });
       }*/
  },
  watch: {
    filters: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue) {
          this.getRecommendedPlans();
        }
      },
    },
  }
}
</script>
