<template>
  <section class="page-wrap error-page">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xxl-6 col-lg-6">
          <div class="text-center">
            <h1>OOPS!</h1>
            <p class="text-capitalize">Landing url is not configured for this <strong><span style="color: #28a745;">{{enrollTypeKey}}</span></strong></p>
            <img src="../assets/images/404.svg" class="img-fluid" alt="404 Error">
            <div class="rollback-btn">
              <button @click="backToHome()" class="backHome">Back to Homepage</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import store, {MUTATION_SHOW_LOADING_SPINNER} from "../store";

export default  {
  name: 'landing-config-error',
  props: ['enrollType', 'redirectionUrl'],
  data () {
    return {
      enrollTypeKey: this.enrollType
    }
  },
  mounted () {
    let app = this
    app.checkLandingInfo();
  },
  methods: {
    checkLandingInfo () {
      if (this.redirectionUrl == 'accessPage') {
        let app = this
         app.clearInfo();
      }
    },
    backToHome () {
      if (this.redirectionUrl == 'accessPage') {
        let app = this
        this.$router.push({name: 'AccessPage'})
        app.clearInfo();
      } else {
        this.$router.push('/')
      }
    },
    clearInfo () {
      let app = this;
      store.commit(MUTATION_SHOW_LOADING_SPINNER, false);
      localStorage.clear();
      app.$store.dispatch("isRepAuthenticated", false);
      app.$store.dispatch("landingInfo", null);
      app.$store.dispatch("landingUrl", null);
      app.$store.dispatch("repInfo", null);
      app.$store.dispatch("groupInfo", null);
    }
  }
}
</script>
