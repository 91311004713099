<template>
  <b-modal
    id="employeeEnrollment-pop"
    centered
    size="md"
    v-model="enrollmentShow"
  >
    <template #modal-header>
      <div class="popUp-header">
        <h4>Employee Enrollment</h4>
        <b-button
          variant="close-btn"
          @click="onClose"
        >
          <i class="fas fa-times"></i>
        </b-button>
      </div>
    </template>
    <b-container fluid>
      <div v-if="!showProceedConformation">
        <div class="form-group mb-3">
          <label>Group Enrollment Code</label>
          <input
            type="text"
            v-model="groupEnrollmentCode"
            @input="removeGroupErrorMessage()"
            :class="{
              'form-control is-invalid':
                $v.groupEnrollmentCode.$error || groupErrorFlag,
            }"
            class="custom-input"
            placeholder="Enter Group Enrollment CodeEg: ABCD1212"
          />
          <div
            class="form-group__message text-left ml-3 mt-2"
            v-if="
              !$v.groupEnrollmentCode.required && $v.groupEnrollmentCode.$error
            "
          >
            Field is required
          </div>
          <div class="error_message mt-2" v-if="groupErrorFlag === true">
            Invalid Group Enrollment Code
          </div>
        </div>
        <div class="form-group semiText">
          <p>
            If you don't know your group enrollment code please contact your
            company's human resource department.
          </p>
        </div>
        <div class="form-group">
          <button class="univ-btn" @click="checkGroupEnrollmentCode()">
            Continue
          </button>
        </div>
      </div>
      <div v-if="showProceedConformation">
        <div class="text-left mb-3">
          <div class="mt-2">
            The company that you will be enrolling under is:<strong
              style="color: #28a745"
            >
              {{ groupInfo.name }}</strong
            >
          </div>
          <div
            class="mt-2 group-not-employer-container"
            @click="backToEnrollmentCode"
          >
            <p>
              If this is NOT the your employer please click here, to re-enter
              your employer enrollment code.
            </p>
            <p>
              If you have questions please contact your Employer for correct
              code.
            </p>
          </div>
        </div>
        <b-row>
          <b-col cols="6" sm="6" md="6" lg="6" xl="6">
            <div class="form-group">
              <button class="univ-btn" @click="onConfirmGroup">Yes</button>
            </div>
          </b-col>
          <b-col cols="6" sm="6" md="6" lg="6" xl="6">
            <button class="univ-btn" @click="resetEnrollGroup">No</button>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </b-modal>
</template>

<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";
import Api from "@/includes/Api";
import store from "../../store";
import { MUTATION_LANDING_LOGIN } from "../../store";
export default {
  name: "EmployeeEnrollment",
  data: () => ({
    groupEnrollmentCode: null,
    showProceedConformation: false,
    groupErrorFlag: false,
    showRepConformation: false,
    repAccessCode: null,
    repErrorFlag: false,
    groupInfo: {
      name: "",
    },
    landingInfo:null,
  }),
  props: {
    enrollmentShow: Boolean,
    captureGroupCode: {
      required: false,
    },
  },
  validations: {
    repAccessCode: {
      required,
    },
    groupEnrollmentCode: {
      required,
    },
  },
  methods: {
    onClose(){
      this.$emit('update:enrollmentShow', false)
      this.showProceedConformation = false;
      this.groupEnrollmentCode=null;
    },
    removeGroupErrorMessage() {
      let app = this;
      app.groupErrorFlag = false;
      app.repErrorFlag = false;
    },
    checkGroupEnrollmentCode() {
      let app = this;
      app.$v.groupEnrollmentCode.$touch();
      if (app.$v.groupEnrollmentCode.$invalid) {
        return false;
      } else {
        axios
          .get(process.env.VUE_APP_API_BASE + "/validate-group", {
            params: {
              group_code: app.groupEnrollmentCode,
            },
          })
          .then(function (response) {
            if (response.data.status == "success") {
              app.groupInfo.name = response.data.data.name;
              app.groupInfo.group_id = response.data.data.group_id;
              app.groupInfo.landing_url = response.data.data.landing_url;
              app.showProceedConformation = true;
            }
            if (response.status == 204) {
              app.groupErrorFlag = true;
              app.showProceedConformation = false;
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    onConfirmGroup() {
      let app = this;
      Api.get(
        "/validate-landing-page?landing_page=" + app.groupInfo.landing_url
      ).then((response) => {
        if (response.data.statusCode == 200) {
          window.localStorage.setItem("has_enrollment",true)
          app.$store.dispatch("isEmployeeEnrollment", true);
          app.showProceedConformation = false;
          let data = response.data.data;
          store.dispatch("landingInfo", data);
          app.landingInfo=data;
          store.commit(MUTATION_LANDING_LOGIN, data);
          window.localStorage.setItem("agent_id", data.data.gagent_code);
          window.localStorage.setItem("portal_id", data.group_id);
          window.localStorage.setItem("group_id", data.group_id);
          window.localStorage.setItem("gcode", data.data.gcode);
          window.localStorage.setItem("gname", data.data.gname);
          app.$store.dispatch('landingUrl', app.groupInfo.landing_url)
          app.$emit("onSuccess");
          app.$emit("update:enrollmentShow", false);
        } 
      });
    },
    backToEnrollmentCode() {
      let app = this;
      app.showProceedConformation = false;
      app.groupEnrollmentCode = null;
      app.$v.$reset();
    },
    resetEnrollGroup() {
      let app = this;
      app.showProceedConformation = false;
      app.$emit("update:enrollmentShow", false);
      app.groupEnrollmentCode = null;
      app.groupErrorFlag = false;
      app.$v.$reset();
    },
  },
};
</script>

<style lang="scss" scoped></style>
