<template>
  <section class="innerpage-wrap">
    <div class="container" ref="saveContactInfo">
      <div class="split-title">
        <h1 class="brand-color">Contact Information</h1>
      </div>
      <div class="enrollmentForm card-block" >
        <form class="" action="index.html" method="post">
          <div class="form-group row">
            <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12  mb-4" v-if="formFields.email_address.required == true">
              <label>Email (Must be Client email) <span class="required">*</span></label>
              <input type="text" class="custom-input"
                     id="email"
                     placeholder="Enter Client Email Address"
                     @input="$v.formFields.email_address.value.$touch(); removeError();"
                     :class="{'form-control is-invalid':$v.formFields.email_address.value.$error || formErrors['email_address_message']}"
                     v-model="formFields.email_address.value"
                     value="" ref="email">
              <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.formFields.email_address.value.required && $v.formFields.email_address.value.$error">
                Field is required
              </div>
              <div class="form-group__message text-left ml-3 mt-2" v-if="$v.formFields.email_address.value.required && $v.formFields.email_address.value.$invalid">
                Email is not in correct format
              </div>
              <div class="error_message" v-if="formErrorFlag == true">{{formErrors['email_address_message']}}</div>
            </div>
            <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12  mb-4" v-if="formFields.phone_number.required == true">
              <label>Contact Number <span class="required">*</span></label>
              <input type="text" class="custom-input"
                     placeholder="Enter Contact Number"
                     @input="$v.formFields.phone_number.value.$touch(); removeError();"
                     :class="{'form-control is-invalid':$v.formFields.phone_number.value.$error || formErrors['phone_number_message']}"
                     v-model="formFields.phone_number.value"
                     value=""
                     maxlength="10" ref="phone" >
              <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.formFields.phone_number.value.required && $v.formFields.phone_number.value.$error">
                Field is required
              </div>
              <div class="form-group__message text-left ml-3 mt-2" v-if="$v.formFields.phone_number.value.required && $v.formFields.phone_number.value.$invalid">
                Invalid phone number
              </div>
              <div class="error_message" v-if="formErrorFlag == true">{{formErrors['phone_number_message']}}</div>
            </div>
            <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12  mb-4" v-if="formFields.address1.required == true">
              <label>Street Address 1 <span class="required">*</span></label>
              <input type="text"
                     class="custom-input"
                     @input="$v.formFields.address1.value.$touch(); removeError();"
                     :class="{'form-control is-invalid':$v.formFields.address1.value.$error || formErrors['address1_message']}"
                     placeholder="Enter Street Address"
                     v-model="formFields.address1.value" value="">
              <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.formFields.address1.value.required && $v.formFields.address1.value.$error">
                Field is required
              </div>
              <div class="error_message ml-3 mt-2"
                   v-if="formErrorFlag == true">
                {{formErrors['address1_message']}}
              </div>
            </div>
            <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12  mb-4" v-if="formFields.address2.required == true">
              <label>Street Address 2 <span class="required">*</span></label>
              <input type="text"
                     class="custom-input"
                     @input="$v.formFields.address2.value.$touch(); removeError();"
                     :class="{'form-control is-invalid':$v.formFields.address2.value.$error || formErrors['address2_message']}"
                     placeholder="Enter
                                   Street Address"
                     v-model="formFields.address2.value" value="">
              <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.formFields.address2.value.required && $v.formFields.address2.value.$error">
                Field is required
              </div>
              <div class="error_message"
                   v-if="formErrorFlag == true">
                {{formErrors['address2_message']}}
              </div>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12  mb-4" v-if="formFields.city.required == true">
              <label>City <span class="required">*</span></label>
              <input type="text"
                     class="custom-input"
                     @input="$v.formFields.city.value.$touch(); removeError();"
                     :class="{'form-control is-invalid':$v.formFields.city.value.$error || formErrors['city_message']}"
                     placeholder="Enter City"
                     v-model="formFields.city.value" value="">
              <div class="form-group__message text-left ml-3 mt-2"  v-if="!$v.formFields.city.value.required && $v.formFields.city.value.$error">
                Field is required
              </div>
              <div class="error_message" v-if="formErrorFlag == true">{{formErrors['city_message']}}</div>
            </div>

            <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12  mb-4" v-if="formFields.state.required == true">
              <label>State <span class="required">*</span></label>
              <input type="text" class="custom-input" disabled="true" placeholder="Enter state" v-model="formFields.state.value" value="" :readonly="formFields.state.required">
              <div class="error_message" v-if="formErrorFlag == true">{{formErrors['state_message']}}</div>
            </div>
            <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12  mb-4" v-if="formFields.zip.required == true">
              <label>Zip <span class="required">*</span></label>
              <input type="text" class="custom-input" disabled="true" style="background-color: -internal-light-dark(rgba(239, 239, 239, 0.3), rgba(59, 59, 59, 0.3));" placeholder="Enter Zip Code" v-model="formFields.zip.value" value="" :readonly="formFields.zip.required" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" maxlength="5">
              <div class="error_message" v-if="formErrorFlag == true">{{formErrors['zip_message']}}</div>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12  mb-4" v-if="formFields.birth_state.required">
              <label>Birth State <span class="required">*</span></label>
                  <b-form-select name=""
                    class="custom-input"
                    @input="$v.formFields.birth_state.value.$touch(); removeError();"
                     :class="{'form-control is-invalid':$v.formFields.birth_state.value.$error || formErrors['birth_state_message']}"
                     v-model="formFields.birth_state.value"
                    id="">
                    <option value="null" disabled>Select birth State</option>
                    <option v-for="state in states" :value="state.abbrev" :key="state.abbrev">{{state.name}} ({{state.abbrev}})</option>
                  </b-form-select>
              <div class="error_message" v-if="formErrorFlag == true">{{formErrors['birth_state_message']}}</div>
            </div>
            <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12  mb-4" v-if="formFields.birth_city.required">
              <label>Birth City <span class="required">*</span></label>
              <input type="text" @input="$v.formFields.birth_city.value.$touch(); removeError();"
                     :class="{'form-control is-invalid':$v.formFields.birth_city.value.$error || formErrors['birth_city_message']}"  class="custom-input" placeholder="Enter birth city" v-model="formFields.birth_city.value">
              <div class="error_message" v-if="formErrorFlag == true">{{formErrors['birth_city_message']}}</div>
            </div>
          </div>

          <div class="form-group">
            <div class="form-check form-check-inline" v-if="formFields.with_validation.required == true">
              <input class="form-check-input" type="checkbox" name="" id="inlinecheckbox1" v-model="formFields.with_validation.value">
              <label class="form-check-label" for="inlinecheckbox1">USPS Address Validation</label>
              <div class="error_message" v-if="formErrorFlag == true">{{formErrors['UspsAddress_message']}}</div>
            </div>
          </div>
        </form>
        <p class="short-msg"><strong>Important:</strong> Applicant will get an electronic copy of the enrollment, and will receive an email within 10 minutes upon completion of enrollment</p>
      </div>
      <div class="action-btn split-title mt-5">
        <router-link to="" @click.native="navigatePrevious" class="dark-btn">Back</router-link>
        <router-link to="" @click.native="saveEnrollment" class="button-green">Save & Continue Later</router-link>
        <div class="action-next">

          <router-link to="" class="button-brand" @click.native="checkEmailPhone" v-if="enrollment_type == 'existing-user'">Next</router-link>
          <router-link to="" class="button-brand" @click.native="saveData" v-else>Next</router-link>
        </div>

      </div>

      <b-modal v-bind:hide-footer="true" id="code-send" centered size="md" >
        <template #modal-header>
          <div class="popUp-header" style="border-bottom: 0px !important">
            <div  v-if="saveSuccess">
              <h4 style="color: green"><b>Information Saved!!!</b> Check your email to continue</h4>
            </div>
            <div v-else>
              <h4>Please enter the alternate email you want to send Enrollment code to?</h4>
            </div>
            <b-button variant="close-btn" @click="$bvModal.hide('code-send')">
              <i class="fas fa-times"></i>
            </b-button>
          </div>
        </template>
        <b-container fluid>

          <div v-if="saveSuccess==false" class="input_box client_email">
            <input type="text"
                   class="custom-input"
                   :class="{'form-control is-invalid': email && !isEmailValid || isEmailNull}"
                   @input="emailValid(); removeError();"
                   v-model="email"
                   value="" />
            <div class="form-group__message  ml-3 mt-2" v-if="email && !isEmailValid || isEmailNull">
              Enter valid email address
            </div>
            <div class="error_message" v-if="formErrorFlag == true">{{formErrors['email_message']}}</div>
          </div>
          <div class="popUp-details text-center">
            <div v-if="saveSuccess" class="action-btn text-center mt-4">
              <b-button style="width:100%;"  class="button-brand mx-1" @click="$bvModal.hide('code-send')" data-dismiss="modal">Done</b-button>
            </div>
            <div v-else class="action-btn text-center mt-4">
              <b-button style="width:40%;"  class="dark-btn mx-1" @click="$bvModal.hide('code-send')">Back</b-button>
              <b-button style="width:40%;"  class="button-brand mx-1" @click="saveAndContinueEnrollment" data-dismiss="modal">Send Code</b-button>
            </div>
          </div>
        </b-container>

      </b-modal>

    </div>
  </section>
</template>

<script>
import axios from 'axios';
import html2canvas from 'html2canvas';
import {required, email} from "vuelidate/lib/validators";
import {checkValidPhoneNumber, checkEmail} from "../../utils/validators";
export default {
  name: "ContactInformation",
  data () {
    return {
      tempId: '',
      // formFields: [],
      formFields: {
        address1: {
          value: '',
          required: false,
          read_only: false,
        },
        address2: {
          value: '',
          required: false,
          read_only: false,
        },
        city: {
          value: '',
          required: false,
          read_only: false,
        },
        zip: {
          value: 0,
          required: false,
          read_only: false,
        },
        phone_number: {
          value: 0,
          required: false,
          read_only: false,
        },
        email_address: {
          value: '',
          required: false,
          read_only: false,
        },
        birth_state: {
          value: '',
          required: false,
          read_only: false,
        },
        birth_city: {
          value: '',
          required: false,
          read_only: false,
        },
        with_validation: {
          value: true,
          required: false,
          read_only: false,
        }
      },
      state: [],
      formErrors: [],
      formErrorFlag: false,
      savePopup: false,
      email: '',
      saveSuccess: false,
      invalidPopup : true,
      email_valid : true,
      phone_valid : true,
      invalid_message: '',
      enrollment_type: '',
      phone_msg: '',
      isEmailNull: false,
      isEmailValid: false,
      checkPrudential: false,
    }
  },
  validations() {
    if (this.formFields.address2.required) {
      if(this.formFields.birth_state.required == true && this.formFields.birth_city.required){
        return {
                formFields: {
                  email_address: {
                    value: {
                      required,
                      email
                    }
                  },
                  phone_number: {
                    value: {
                      required,
                      checkValidPhoneNumber
                    }
                  },
                  city: {
                    value: {
                      required
                    }
                  },
                  address1: {
                    value: {
                      required
                    }
                  },
                  address2: {
                    value: {
                      required
                    }
                  },
                  birth_state: {
                    value: {
                       required
                       }
                  },
                  birth_city: {
                    value: {
                       required
                       }
                  },
                }
              }
      }
      else{
        return {
                formFields: {
                  email_address: {
                    value: {
                      required,
                      email
                    }
                  },
                  phone_number: {
                    value: {
                      required,
                      checkValidPhoneNumber
                    }
                  },
                  city: {
                    value: {
                      required
                    }
                  },
                  address1: {
                    value: {
                      required
                    }
                  },
                  address2: {
                    value: {
                      required
                    }
                  }
                }
              }
      }

    }
     else {
       if(this.formFields.birth_state.required == true && this.formFields.birth_city.required){
          return {
                  formFields: {
                    email_address: {
                      value: {
                        required,
                        email
                      }
                    },
                    phone_number: {
                      value: {
                        required,
                        checkValidPhoneNumber
                      }
                    },
                    city: {
                      value: {
                        required
                      }
                    },
                    address1: {
                      value: {
                        required
                      }
                    },
                    birth_state: {
                    value: {
                       required
                       }
                  },
                  birth_city: {
                    value: {
                       required
                       }
                  },
                  }
                }
       }
      else{
            return {
            formFields: {
              email_address: {
                value: {
                  required,
                  email
                }
              },
              phone_number: {
                value: {
                  required,
                  checkValidPhoneNumber
                }
              },
              city: {
                value: {
                  required
                }
              },
              address1: {
                value: {
                  required
                }
              }
            }
          }
      }
    }
  },
  mounted() {
    let app = this;
    app.tempId = window.localStorage.getItem('tempId');
    app.getStates();
    app.scrollTop();
    app.checkPrudentialCart();
    app.getFields();
    app.getEnrollmentType();
  },
  methods: {
    validateEmail: function () {
      let app = this;
      let params = {
        email: app.formFields.email_address.value,
      };
      axios.post(process.env.VUE_APP_API_BASE+'/validate-member-email', params)
          .then(function (response) {
            console.log(response.data.data);
            if (response.data.status == 'success') {
              app.formErrorFlag = false;
              app.formErrors['email_address_message'] = '';
            }
          })
          .catch(function (error) {
            console.log(error.response.data.data);
            if (error.response.data.status == 'error') {
              error.response.data.data.forEach(function(item) {
                app.formErrorFlag = true;
                app.formErrors[item.target_element+'_message'] = item.error_message;
              });
            }
          });
    },
    getStates: function () {
      let app = this;
      axios.get(process.env.VUE_APP_API_BASE+'/get-states')
          .then(function (response) {
            if (response.data.status == 'success') {
              app.states = response.data.data.result;
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    getFields: function () {
      let app = this;
      axios.get(process.env.VUE_APP_API_BASE+'/get-form-fields-contact-info', {
        params: {
          enrollment_id: app.tempId,
        }
      })
          .then(function (response) {
            console.log(response.data.data);
            if (response.data.status == 'success') {
              response.data.data.result.forEach(function(item) {
                app.formFields[item.field_name] = item;
              });
            }
            app.fetchInfo();
            console.log(app.formFields);
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    fetchInfo: function () {
      let app = this;
      axios.get(process.env.VUE_APP_API_BASE+'/get-enrollment-temp-info', {
        params: {
          enrollment_id: app.tempId,
          column: 'contact_info',
        }
      })
          .then(function (response) {
            if (response.data.status == 'success') {
              console.log('This is the fetched data'+JSON.stringify(response.data.data.result));
              app.formFields.address1.value=response.data.data.result.address1;
              app.formFields.city.value=response.data.data.result.city;
              app.formFields.phone_number.value=response.data.data.result.phone_number;
              app.formFields.email_address.value=response.data.data.result.email_address;
              app.formFields.birth_state.value=response.data.data.result.birth_state;
              app.formFields.birth_city.value=response.data.data.result.birth_city;
              app.formFields.with_validation.value=response.data.data.result.with_validation;
              app.formFields.zip4.value=response.data.data.result.zip4;
              // for(var i in response.data.data.result) {
              //     fetchedData.push([i, response.data.data.result [i]]);
              // }
              // fetchedData.forEach(function(item) {
              //     let key = item[0];
              //     let val = item[1];
              //     app.formFields[key]['value'] = val;
              // });
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    saveData: function () {
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log('***** Inside Save Data Invalid*****')
        return false
      } else {
        const el = this.$refs.saveContactInfo;
        (async () => {
          this.saveScreenShot((await html2canvas(el)).toDataURL('image/png'), 'contact_info');
          this.screenShotData = (await html2canvas(el)).toDataURL();
        })();
        // Submit API
        let app = this;
        var with_validation = 0;
        if (app.formFields.with_validation.value == true) {
          with_validation = 1;
        } else {
          with_validation = 0;
        }
        app.formErrorFlag = false;
        app.formErrors = [];
        let params = {
          enrollment_id: app.tempId,
          address1: app.formFields.address1.value,
          address2: app.formFields.address2.value,
          city: app.formFields.city.value,
          state: app.formFields.state.value,
          zip: app.formFields.zip.value,
          phone_number: app.formFields.phone_number.value,
          email_address: app.formFields.email_address.value,
          birth_state:app.formFields.birth_state.value,
          birth_city:app.formFields.birth_city.value,
          with_validation: with_validation,
        };
        //discard null values
        for (const key of Object.keys(params)) {
          if (params[key] === null) {
            delete params[key];
          }
        }
        console.log(params);
        axios.post(process.env.VUE_APP_API_BASE + '/save-contact-info', params)
            .then(function (response) {
              if (response.data.status == 'success') {
                console.log("success");
                app.saveAndContinue();
              }
            })
            .catch(function (error) {
              if (error.response.data.status == 'error') {
                error.response.data.data.forEach(function (item) {
                  app.formErrorFlag = true;
                  app.formErrors[item.target_element + '_message'] = item.error_message;
                });
              }
            });
      }
    },
    checkEmailPhone () {
      let app = this;
      app.$v.$touch();
      if (app.$v.$invalid) {
        console.log('***** Inside Save Data Invalid*****')
        return false
      } else {
        axios.get(process.env.VUE_APP_API_BASE + '/validate-phone-numverify', {
          params: {
            phone: app.formFields.phone_number.value,
          }
        })
            .then(function (response) {
              if (response.data.status == 'success') {
                console.log("success");
                app.phone_valid = true;
              }
            })
            .catch(function (error) {
              if (error.response.data.status == 'error') {
                error.response.data.data.forEach(function(item) {
                  app.formErrorFlag = true;
                  app.formErrors[item.target_element+'_number_message'] =item.error_message;
                });
                app.phone_valid = false;
              }
            });
        let params = {
          email: app.formFields.email_address.value
        }
        axios.post(process.env.VUE_APP_API_BASE + '/validate-email-neverbounce', params)
            .then(function (response) {
              if (response.data.status == 'success') {
                console.log("success");
                app.email_valid = true;
                if (app.phone_valid == true) {
                  app.saveData();
                }
              }
            })
            .catch(function (error) {
              if (error.response.data.status == 'error') {
                error.response.data.data.forEach(function(item) {
                  app.formErrorFlag = true;
                  app.formErrors[item.target_element+'_address_message'] =item.error_message;
                });
                app.email_valid = false;
              }
            });
      }
    },
    focusCursor() {
      let app = this;
      if (!app.email_valid) {
        app.$refs.email.focus();
      } else if (!app.phone_valid) {
        app.$refs.phone.focus();
      }
      app.closePopupInvalid();
    },
    getEnrollmentType() {
      let app = this;
      axios.get(process.env.VUE_APP_API_BASE+'/get-enrollment-temp-info',  {
        params: {
          enrollment_id: app.tempId,
          column: 'type',
        }
      })
          .then(function (response) {
            if (response.data.status == 'success') {
              app.enrollment_type = response.data.data.result;
            }
          })
          .catch(function (error) {
            if (error.response.data.status == 'error') {
              console.log('error');
              app.enrollment_type = '';
            }
          });
    },
    saveAndContinue() {
      //Update APIs
      //Navigate to next page
      if (localStorage.getItem("navto") === null || localStorage.getItem("navto") === undefined || localStorage.getItem("navto") === '') {
        this.$emit('comEnvNavigation', this.$route.name);
      }else{
        window.localStorage.removeItem('navto');
        this.$router.push('review-confirm');
      }
    },
    saveEnrollment : function () {
      let app = this;
      app.$bvModal.show('code-send');
    },
    saveAndContinueEnrollment: function () {
      if (!this.isEmailValid) {
        this.isEmailNull = true;
      } else {
        this.isEmailNull = false;
        let app = this;
        let params = {
          address1: app.formFields.address1.value,
          address2: app.formFields.address2.value,
          city: app.formFields.city.value,
          state: app.formFields.state.value,
          zip: app.formFields.zip.value,
          phone_number: app.formFields.phone_number.value,
          email_address: app.formFields.email_address.value,
        };
        for (const key of Object.keys(params)) {
          if (params[key] === null) {
            delete params[key];
          }
        }
        let data = {
          enrollment_id: app.tempId,
          email: app.email,
          tab: 'contact_info',
          data: params
        }
        console.log('called');
        console.log(this.saveEmail);
        console.log(params);
        app.formErrorFlag = false;
        app.formErrors = [];
        axios.post(process.env.VUE_APP_API_BASE + '/save-enrollment', data)
            .then(function (response) {
              if (response.data.status == 'success') {
                app.saveSuccess = true;
                console.log("success");
                // app.saveAndContinue();
              }
            })
            .catch(function (error) {
              if (error.response.data.status == 'error') {
                console.log('error');
                error.response.data.data.forEach(function (item) {
                  app.formErrorFlag = true;
                  app.formErrors[item.target_element + '_message'] = item.error_message;
                });
                console.log(app.formErrors);
              }
            });
      }
    },
    closePopup : function () {
      let app = this;
      app.savePopup = false;
    },
    closePopupInvalid : function () {
      let app = this;
      app.email_valid = true;
      app.phone_valid = true;
    },
    navigatePrevious() {
      //Update APIs
      //Navigate to next page
      this.$emit('comEnvNavigationBack', this.$route.name);
    },
    checkPrudentialCart() {
      let app = this;
      axios.get(process.env.VUE_APP_API_BASE+'/check-prudential-cart', {
        params: {
          enrollment_id: app.tempId,
        }
      })
          .then(function (response) {
            if (response.data.status == 'success') {
              app.checkPrudential = response.data.data.status;
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    },
    saveScreenShot (image, name) {
      let app = this;
      let data = {
        "enrollment_id" : app.tempId,
        "name" : name,
        "image" : image
      };
      let config = {
        method: 'post',
        url: process.env.VUE_APP_API_BASE+'/save-screenshot',
        headers: { },
        data : data
      };
      axios(config)
          .then(function (response) {
            if (response.data.status == 'success') {
              console.log('screenshot saved');
            }
          })
          .catch(function (error) {
            console.log('screenshot not saved');
            console.log(error);
          });
    },

    scrollTop(){
      window.scrollTo(0,0);
    },
    checkPhone () {
      let app = this;
      if (app.formFields.phone_number.value.length > 9) {
        axios.get(process.env.VUE_APP_API_BASE+'/validate-phone-numverify',  {
          params: {
            phone: app.formFields.phone_number.value,
          }
        })
            .then(function (response) {
              if (response.data.status == 'success') {
                app.formErrorFlag = false;
                app.formErrors['phone_number_message'] = '';
              }
            })
            .catch(function (error) {
              if (error.response.data.status == 'error') {
                error.response.data.data.forEach(function(item) {
                  if (item.target_element == 'phone') {
                    app.formErrorFlag = true;
                    app.formErrors['phone_number_message'] = item.error_message;
                  }
                });
              }
            });
      }
    },

    removeError () {
      this.formErrors = []
    },
    emailValid () {
      this.isEmailValid = checkEmail(this.email)
      this.isEmailNull = !checkEmail(this.email)
    }
  }
}
</script>

<style scoped>
</style>
