<template>
  <section class="content_container">
    <section class="innerpage-wrap">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="breadcrumb">
              <ul>
                <li><a href="#" @click.prevent="homePage">Home</a></li>
                <li v-if="isCurrentMemberAvailable"><a style="color:#a2a2a2" @click="routeToCurrentRoute">Current Member</a></li>
                <li><a href=""  @click.prevent="planListing">Plans</a></li>
                <li><a href="#" >Plan Details</a></li>
              </ul>
            </div>
          </div>
          <plan-detail></plan-detail>
        </div>
      </div>
    </section>
    <!--    </div>-->
  </section>
</template>

<script>
  import axios from 'axios';
  import PlanDetail from "../components/plan/PlanDetail";

  export default {
    name: "Plan",
    components: {
      'plan-detail' : PlanDetail
    },
    data: function () {
        return {
          tempId: '',
          gname: '',
          gcode: '',
          username: '',
          usertype: '',
          eprocess: '',
          isCurrentMemberAvailable: false
        }
    },
    mounted() {
        let app = this;
        app.tempId = window.localStorage.getItem('tempId');
        app.eprocess = window.localStorage.getItem('eprocess');
        app.open_type = window.localStorage.getItem('open_type');
        app.referral = window.localStorage.getItem('referral');
        app.gId = window.localStorage.getItem('group_id');
        app.isCurrentMemberAvailable = window.localStorage.getItem('isCurrentMemberAvailable');
        app.getInfo();
    },
    methods: {
      homePage: function(){
        if (this.open_type !== null && typeof(this.open_type) !== 'undefined') {
          if (this.referral !== null && typeof(this.referral) !== 'undefined') {
            this.$router.push('/referrals?rid='+this.referral);
          } else {
            this.$router.push('/');
          }
        } else {
          if (this.eprocess === 'rep') {
            this.$router.push('/agent-home');
          }else if (this.eprocess === 'applicant') {
            this.$router.push('/member-home');
          } else if (this.eprocess === 'group') {
            if (location.host == process.env.VUE_APP_SITE_HOST_NAME) {
              this.$router.push('/');
            } else {
              this.$router.push('/group-home');
            }
          }
        }
      },
      planListing: function(){
        this.$router.push('/plans');
      },
      getInfo: function() {
        let app = this;
        let url;
        if(app.tempId == null)
        {
          url = process.env.VUE_APP_API_BASE+'/get-group-agent-member-info?group_id='+app.gId;
        }
        else {
          url = process.env.VUE_APP_API_BASE+'/get-group-agent-member-info?enrollment_id='+app.tempId;
        }
            axios.get(url)
            .then(function (response) {
                if (response.data.status == 'success') {
                   app.usertype = response.data.data.enrollment_type;
                   if (response.data.data.enrollment_type == 'existing-user') {
                      app.username = response.data.data.result.user_info.first_name+' '+response.data.data.result.user_info.last_name;
                   }

                   app.gname = response.data.data.result.group_info.name;
                   app.gcode = response.data.data.result.group_info.code;
                }

            })
            .catch(function (error) {
                console.log(error);
            });
      },
      checkEprocess: function() {
        let app = this;
        axios.get(process.env.VUE_APP_API_BASE+'/get-enrollment-temp-info', {
          params: {
            enrollment_id: app.tempId,
            column: 'eprocess',
          }
        })
        .then(function (response) {
          console.log(response.data.data.result);
          if (response.data.status == 'success') {
            app.eprocess = response.data.data.result;
          }

        })
        .catch(function (error) {
          console.log(error);
        });
      },
      routeToCurrentRoute () {
        if (this.open_type !== null && typeof(this.open_type) !== 'undefined') {
          if (this.referral !== null && typeof(this.referral) !== 'undefined') {
            this.$router.push('/referrals?rid='+this.referral);
          } else {
            this.$router.push('/');
          }
        } else {
          if (this.eprocess === 'rep') {
            this.$router.push('/agent-select-member');
          }else if (this.eprocess === 'applicant') {
            this.$router.push('/member-home');
          } else if (this.eprocess === 'group') {
            if (location.host == process.env.VUE_APP_SITE_HOST_NAME) {
              this.$router.push('/');
            } else {
              this.$router.push('/group-home');
            }
          }
        }
      }
    }
  }
</script>

<style >
@import url('../assets/css/plan_desc.css');
</style>
