const state = {
  isRepAuthenticated: false,
  landingInfo: null,
  landingUrl: null,
  repInfo: null,
  groupInfo: null,
  isEmployeeEnrollment:false,
  isContinueEnrollmentLandingPage: false
}

const mutations = {
  setRepAuthenticatedState: (state, payload) => {
    state.isRepAuthenticated = payload
  },
  setLandingInfo: (state, payload) => {
    state.landingInfo = payload
  },
  setLandingUrl: (state, payload) => {
    state.landingUrl = payload
  },
  setRepInfo: (state, payload) => {
    state.repInfo = payload
  },
  setGroupInfo: (state, payload) => {
    state.groupInfo = payload
  },
  setEmployeeEnrollmentState: (state, payload) => {
    state.isEmployeeEnrollment = payload
  },
  setContinueEnrollmentFlag: (state, payload) => {
    state.isContinueEnrollmentLandingPage = payload
  }
}

const actions = {
  isEmployeeEnrollment: ({commit}, payload) => {
    commit('setEmployeeEnrollmentState', payload)
  },
  isRepAuthenticated: ({commit}, payload) => {
    commit('setRepAuthenticatedState', payload)
  },
  landingInfo: ({commit}, payload) => {
    commit('setLandingInfo', payload)
  },
  landingUrl: ({commit}, payload) => {
    commit('setLandingUrl', payload)
  },
  repInfo: ({commit}, payload) => {
    commit('setRepInfo', payload)
  },
  groupInfo: ({commit}, payload) => {
    commit('setGroupInfo', payload)
  },
  continueEnrollmentInfo: ({commit}, payload) => {
    commit('setContinueEnrollmentFlag', payload)
  }
}

export default {
  state,
  mutations,
  actions
}
