const state = {
  zipCode: null
}

const mutations = {
  setZipCodeFromLocation: (state, payload) => {
    state.zipCode = payload
  }
}

const actions = {
  zipCode: ({commit}, payload) => {
    commit('setZipCodeFromLocation', payload)
  },
}

export default {
  state,
  mutations,
  actions
}
