 <template>
  <section class="page-wrap">
    <div class="banner-wrap pb-5">
     <landing-page></landing-page>
    </div>
<!--     <featured-plans/>-->
    <recommended-plans class="mt-4"/>
  </section>
</template>
<script>
// import FeaturedPlans from '../components/FeaturedPlans.vue'
import {required} from "vuelidate/lib/validators";
import {checkValidZip, isOver18} from '../utils/validators';
import axios from 'axios';
import RecommendedPlans from "../components/RecommendedPlans.vue"
import LandingPage from "../components/LandingPage";
export default {
  name: "Banner",
  components: {
    //FeaturedPlans,
    LandingPage,
    RecommendedPlans
  },
  data: function () {
    return {
      showSlide1: true,
      showSlide2: false,
      showSlide3: false,
      slideIndex: 'first',
      buttonText: 'SUBMIT',
      zip: '',
      dob: '',
      state: '',
      gender: '',
      is_smoker: '',
      tier: '',
      eprocess: 'rep',
      agent_id: '10109',
      group_id: '77',
      apiResp: false,
      zipFlag: false,
      secondflag: false,
      thirdflag: false,
      zip_error_message: '',
      second_error_message: '',
      third_error_message: '',
      formErrorFlag: [],
      formErrors: [],
      platformId: '',
      enrollType: '',
      format:'MMM-dd-yyyy',
      open_type: '',
      genderOptions: [
        { value: '', text: 'Choose Gender', disabled: true},
        { value: '0', text: 'Male' },
        { value: '1', text: 'Female'}
      ],
      tierOptions: [
        { value: '', text: 'Choose Tier', disabled: true},
        { value: 'IO', text: 'Member Only'},
        { value: 'IS', text: 'Member + Spouse'},
        { value: 'IC', text: 'Member + Children'},
        { value: 'IF', text: 'Family'}
      ],
      isPremierPlanAddedOnCart: false,
      bannerImage: '',
      siteUrl: process.env.VUE_APP_IS_GO_ENROLL,
      goEnrollLandingImage: '',
      hostName: location.host,
      envAssignHostName: process.env.VUE_APP_SITE_HOST_NAME
    }
  },
  validations: {
    zip: {
      required,
      checkValidZip
    },
    tier: {
      required
    },
    dob: {
      required,
      isOver18
    },
    gender: {
      required
    }
  },
  mounted() {
    let app = this;
    app.tempId = window.localStorage.getItem('tempId');
    app.agent_id = window.localStorage.getItem('AGENT_ID');
    app.group_id = window.localStorage.getItem('group_id');
    app.platformId = window.localStorage.getItem('portal_id');
    app.enrollType = window.localStorage.getItem('enroll_type');
    app.eprocess = window.localStorage.getItem('eprocess');
    app.open_type = window.localStorage.getItem('open_type');
    app.getFilters();
    app.checkPremierPlan();
    localStorage.removeItem('selectedCategorykey');
    localStorage.removeItem('selectedCategoryValue');
    app.fetchCatchInfo();
  },
  methods: {
    autoDateFormat (testdate) {
      var date_regex = /^\d{2}\/\d{2}\/\d{4}$/ ;
      date_regex.test(testdate);
      console.log('**** date regex ****', testdate)
    },
    buttonNavigate: function () {
      switch (this.slideIndex) {
        case "first":
          var zipValid = this.validateZip();
          console.log(zipValid);
          setTimeout(() => {
            if (this.validateZip() == false) {
              console.log('hee');
              this.slideIndex = 'second';
              this.showSlide1 = false;
              this.showSlide2 = true;
            }else{
              console.log('here');
              this.slideIndex = 'first';
              this.showSlide1 = true;
              this.showSlide2 = false;
            }
          }, 2000);

          break;
        case "second":
          if (this.gender !='' && this.is_smoker != '') {
            this.slideIndex = 'third';
            this.showSlide2 = false;
            this.showSlide3 = true;
            this.buttonText = 'SUBMIT';
            this.secondflag = false;
            this.second_error_message = '';
          } else {
            this.slideIndex = 'second';
            this.showSlide2 = true;
            this.showSlide3 = false;
            this.secondflag = true;
            this.second_error_message = 'Please fill the both fields.';
          }
          break;
      }
    },
    viewSlide: function (val) {
      switch (val) {
        case "first":
          this.slideIndex = 'first';
          this.showSlide1 = true;
          this.showSlide2 = false;
          this.showSlide3 = false;
          this.buttonText = 'NEXT';
          break;
        case "second":
          this.slideIndex = 'second';
          this.showSlide1 = false;
          this.showSlide2 = true;
          this.showSlide3 = false;
          this.buttonText = 'NEXT';
          break;
        case "third":
          this.slideIndex = 'third';
          this.showSlide1 = false;
          this.showSlide2 = false;
          this.showSlide3 = true;
          this.buttonText = 'SUBMIT';
          break;
      }
    },
    getFilters: function () {
      let app = this;
      if(app.tempId != null){
      axios.get(process.env.VUE_APP_API_BASE + '/get-enrollment-temp-info', {
        params: {
          enrollment_id: app.tempId,
          column: 'filter_info'
        }
      })
          .then(function (response) {
            if (response.data.status == 'success') {
              app.dob = response.data.data.result.dob;
              app.zip = response.data.data.result.zip;
              app.state = response.data.data.result.state;
              app.tier = response.data.data.result.tier;
              app.gender = response.data.data.result.gender;
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    },
    submitAPI: function () {
      this.$v.$touch();
      if (this.$v.$invalid) {
        console.log('***** Inside Save Data Invalid*****')
        return false
      } else {
        // Submit API and
        // Navigate to plans page
        let app = this;
        let params = {
          zip: app.zip,
          dob: app.dob,
          is_smoker: app.is_smoker,
          gender: app.gender,
          tier: app.tier,
          eprocess: app.eprocess,
          agent_id: app.agent_id,
          group_id: app.group_id,
          annual_incode: app.annualIncome,
          user_id: app.userId,
          open_type: app.open_type,
        };
        if (app.tempId == null) {
          axios.post(process.env.VUE_APP_API_BASE + '/add-filter-for-new-user', {
            zip: app.zip,
            dob: app.dob,
            // is_smoker: app.is_smoker,
            gender: app.gender,
            tier: app.tier,
            eprocess: app.eprocess,
            agent_id: app.agent_id,
            group_id: app.group_id,
            platform_group_id: app.platformId,
            type: app.enrollType,
            open_type: app.openType,
          })
              .then(function (response) {
                if (response.data.status == 'success') {
                  window.localStorage.setItem('tempId', response.data.data.temp_enrollment_id);
                  window.localStorage.setItem('show-quotation', true);
                  window.localStorage.setItem('isFilterDataSubmitted', true);
                  console.log("success");
                  app.formErrorFlag = [];
                  app.formErrors = [];
                  app.$bus.$emit('getMenuList')
                  if (app.tempId != null) {
                    app.$root.$emit("cartData", app.tempId);
                  }
                  app.nextPage(true);
                }
              })
              .catch(function (error) {
                app.formErrorFlag = [];
                app.formErrors = [];
                console.log(error);
                if (error.response.data.status == 'error') {
                  error.response.data.data.forEach(function (item) {
                    app.formErrorFlag[item.target_element] = true;
                    app.formErrors[item.target_element + '_message'] = item.error_message;
                  });
                }
              });
        } else {
          for (const key of Object.keys(params)) {
            if (params[key] === null || params[key] === '' || params[key] === undefined) {
              delete params[key];
            }
          }
          axios.put(process.env.VUE_APP_API_BASE + '/change-filter-info/' + app.tempId, params)
              .then(function (response) {
                if (response.data.status == 'success') {
                  app.isHidden = false
                  app.getFilters();
                  app.$bus.$emit('getMenuList')
                  if (app.tempId != null) {
                    app.$root.$emit("cartData", app.tempId);
                  }
                  app.nextPage(true);

                }
              })
              .catch(function (error) {
                app.formErrorFlag = [];
                app.formErrors = [];
                console.log(error);
                if (error.response.data.status == 'error') {
                  error.response.data.data.forEach(function (item) {
                    app.formErrorFlag[item.target_element] = true;
                    app.formErrors[item.target_element + '_message'] = item.error_message;
                  });
                }
              });
        }
      }

  },
    validateZip: function () {
      let app = this;
      axios.get(process.env.VUE_APP_API_BASE+'/validate-zip-code', {
        params: {
          zip_code: app.zip,
        },
      })
          .then(function (response) {
            console.log(response);
            app.zip_error_message = '';
            app.zipFlag = false;
          })
          .catch(function (error) {
            console.log(error.response.data.data[0]);
            if (error.response.data.status == 'error') {
              // error.response.data.data.forEach(function(item) {
              app.zipFlag = true;
              app.zip_error_message = error.response.data.data[0].error_message;
              // });
            }
          });
      return app.zipFlag;
    },
    nextPage: function(respFlag) {
      if (respFlag == true) {
        this.$router.push('plans');
      }
    },
    checkValue: function(str, max) {
      if (str.charAt(0) !== '0' || str == '00') {
        var num = parseInt(str);
        if (isNaN(num) || num <= 0 || num > max) num = 1;
        str = num > parseInt(max.toString().charAt(0)) && num.toString().length == 1 ? '0' + num : num.toString();
      }
      return str;
    },
    eventDob: function() {
      console.log('******** event Dob ******')
      let app = this;
      var input = app.dob;
      if (/\D\/$/.test(input)) input = input.substr(0, input.length - 1);
      var values = input.split('/').map(function(v) {
        return v.replace(/\D/g, '')
      });
      if (values[0]) values[0] = app.checkValue(values[0], 12);
      if (values[1]) values[1] = app.checkValue(values[1], 31);
      var output = values.map(function(v, i) {
        return v.length == 2 && i < 2 ? v + '/' : v;
      });
      app.dob = output.join('').substr(0, 10);
    },
    removeError () {
      this.formErrors = []
    },
    homePage: function(){
      if (this.open_type !== null && typeof(this.open_type) !== 'undefined') {
        if (this.referral !== null && typeof(this.referral) !== 'undefined') {
          this.$router.push('/referrals?rid='+this.referral);
        } else {
          this.$router.push('/');
        }
      } else {
        if (this.eprocess === 'rep') {
          this.$router.push('/agent-home');
        }else if (this.eprocess === 'applicant') {
          this.$router.push('/member-home');
        } else if (this.eprocess === 'group') {
          if (location.host == process.env.VUE_APP_SITE_HOST_NAME) {
            this.$router.push('/');
          } else {
            this.$router.push('/group-home');
          }
        }
      }
    },
    checkPremierPlan () {
      // Check L713 plan on cart.
      let app = this
      if (app.tempId !== null) {
        axios.get(process.env.VUE_APP_API_BASE+'/get-more-requirement', {
          params: {
            enrollment_id: app.tempId,
          }
        })
        .then(function (response) {
          app.isPremierPlanAddedOnCart = response.data.data
          if (app.isPremierPlanAddedOnCart) {
            app.tierOptions = [
              {value: '', text: 'Choose Tier', disabled: true},
              {value: 'IO', text: 'Member Only'},
              {value: 'IS', text: 'Member + Spouse'},
              {value: 'IC', text: 'Member + 1 Child'},
              {value: 'IF', text: 'Family'}
            ]
          } else {
            app.tierOptions = [
              {value: '', text: 'Choose Tier', disabled: true},
              {value: 'IO', text: 'Member Only'},
              {value: 'IS', text: 'Member + Spouse'},
              {value: 'IC', text: 'Member + Children'},
              {value: 'IF', text: 'Family'}
            ]
          }
        })
        .catch(function (error) {
          console.log(error)
        });
      }
    },
    fetchCatchInfo() {
      let siteDomain = window.btoa(unescape(encodeURIComponent(process.env.VUE_APP_SITE_DOMAIN)))
      // let siteDomain = 'aHR0cHM6Ly9lbnJvbGwucHVyZW5yb2xsLmNvbQ=='
      let url = `${process.env.VUE_APP_API_BASE}/get-external-site-dynamic-information?site_domain=${siteDomain}`
      caches.open('fetch_color_cache').then((cache) => {
        cache.match(url).then(response => {
          response.json().then(data => {
            let getCatchColor = []
            let newObj = {}
            getCatchColor = data
            getCatchColor.map(item => {
              newObj[item.key] = item.value;
            });
            this.bannerImage = newObj["VUE_APP_BANNER_IMAGE"]
            this.goEnrollLandingImage = newObj["VUE_APP_GO_ENROLL_BANNER_IMAGE"]
          })
        })
      }).catch((err) => {
        console.log(err);
      })
    }
  },
  listener: {
    'getGroupDetail'() {
      if (location.host == process.env.VUE_APP_SITE_HOST_NAME) {
         let app = this
          app.group_id = window.localStorage.getItem('group_id');
          app.platformId = window.localStorage.getItem('portal_id');
      }
    }
  }
}
</script>
<style lang="scss">
.form-select {
  width: 100%;
  border: 1px solid #dddddd;
  border-radius: 6px;
  font-size: 14px;
  padding: 10px 15px;
  outline: none;
  font-weight: 500;
}


</style>
