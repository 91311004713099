<template>
  <section class="content_container plan_confirm">
    <div class="thank_you text-center">
      <div class="img">
        <img :src="thankYouImage" width="45%" alt="Corenroll">
      </div>
      <div class="content mt-3">
        <!-- <h1>
          <span class="head_grey">Thank you for choosing our</span><br>Insurance Plans.
        </h1> -->
        <h2 v-for="eff in effective" :value="eff" :key="eff">Your plans will be effective as of {{eff}}</h2>
        <div class="row proceed-btn">
          <div class="col-md-6 p-0 ">
            <button class="proceed w-100 m-0"  type="button" id="beginEnroll" name="button" @click="nextPage('rep')">Rep Enrollment</button>
          </div>
          <div class="col-md-6 pe-0">
            <button class="proceed w-100 m-0" type="button" id="beginEnroll" name="button" @click="nextPage('applicant')">Member Enrollment</button>
          </div>
          <div class="col-md-12 pt-1">
            <button class="back w-50 mt-2" type="button" id="backCart" name="button" @click="navigatePrevious">Back to Cart</button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// import axios from 'axios';


export default {
    name: "Confirmation",
    props:{
    effective:Array,
  },
    data: function () {
      return {
        tempId : window.localStorage.getItem('tempId'),
        thankYouImage: ''
      }
    },
    mounted() {
        let app = this;
        app.tempId = window.localStorage.getItem('tempId');
        app.fetchCatchInfo()
    },
    methods: {
        nextPage: function(type) {
          this.$emit('Checkoutcart',type);
          localStorage.setItem('enrollment','new_enrollment');
          this.$router.push('enrollment/personal-info');
        },
        navigatePrevious: function() {
          this.$emit('hidePopUp');
        },
        fetchCatchInfo() {
          let siteDomain = window.btoa(unescape(encodeURIComponent(process.env.VUE_APP_SITE_DOMAIN)))
          // let siteDomain = 'aHR0cHM6Ly9lbnJvbGwucHVyZW5yb2xsLmNvbQ=='
          let url = `${process.env.VUE_APP_API_BASE}/get-external-site-dynamic-information?site_domain=${siteDomain}`
          caches.open('fetch_color_cache').then((cache) => {
            cache.match(url).then(response => {
              response.json().then(data => {
                let getCatchColor = []
                let newObj = {}
                getCatchColor = data
                getCatchColor.map(item => {
                  newObj[item.key] = item.value;
                });
                this.thankYouImage = newObj["VUE_APP_THANK_YOU"]
              })
            })
          }).catch((err) => {
            console.log(err);
          })
        }
    }
}
</script>

<style scoped>

</style>
